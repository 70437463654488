import { useCallback, useMemo, useState } from "react"

import BN from "bignumber.js"
import { get } from "lodash"
import { useWallet } from "use-wallet"
import web3 from "web3"

import {
  buyNowAuction as buyNowContract,
  traditionalAuction as traditionalContract,
  useContract,
  executeMethod,
} from "@monegraph/contracts"
import { AuctionStatus, AuctionType } from "@monegraph/graph"

export enum BuyButtonState {
  Biddable,
  Buyable,
  Settleable,
  Sold,
}

export default function useBuyButton(auction: any) {
  const wallet = useWallet()
  const [errorState, setErrorState] = useState<string | null>()

  const { isFinalized, auctionContract } = useMemo(() => {
    return {
      isFinalized:
        get(auction, "status", AuctionStatus.OPEN) === AuctionStatus.FINALIZED,
      auctionContract:
        auction.type === AuctionType.BUYNOW
          ? buyNowContract
          : traditionalContract,
    }
  }, [auction])
  const buttonState = useMemo(() => {
    if (isFinalized) {
      return BuyButtonState.Sold
    } else if (get(auction, "hasEnded")) {
      return BuyButtonState.Settleable
    } else if (auction.type === AuctionType.BUYNOW) {
      return BuyButtonState.Buyable
    } else {
      return BuyButtonState.Biddable
    }
  }, [auction, isFinalized, wallet.status])

  const contract = useContract(auctionContract, {
    address: auction.id,
  })

  useMemo(() => {
    if (!wallet.account) {
      // @ts-ignore
      wallet.connectors.injected
        // @ts-ignore
        .web3ReactConnector(process.env.GATSBY_ETHERNET_CHAIN_ID as string)
        .isAuthorized()
        .then((authorized: boolean) => {
          if (authorized) {
            wallet.connect("injected")
          }
        })
    }
  }, [])

  const onSettle = useCallback(async () => {
    try {
      if (!isFinalized && auction.hasEnded) {
        await executeMethod(contract.finalize, [], {
          from: wallet.account,
        })

        setErrorState(null)
      }
    } catch (e) {
      switch (e.code) {
        // user rejected the transaction
        case 4001:
          throw e

        // insufficient funds
        case -32000:
          setErrorState(
            "Please deposit ETH into your metamask wallet, insufficient funds to complete transaction."
          )
          throw e

        default:
          throw e
      }
    }
  }, [isFinalized, auction.hasEnded, contract, wallet.account])

  const onBuy = useCallback(
    async bid => {
      const minimumBid = BN.isBigNumber(auction.minimumBid)
        ? auction.minimumBid
        : new BN(auction.minimumBid)

      const bidInWei =
        typeof bid === "string" ? web3.utils.toWei(bid, "ether") : minimumBid

      try {
        await executeMethod(contract.bid, [], {
          from: wallet.account,
          value: minimumBid.gt(bidInWei) ? auction.minimumBid : bidInWei,
        })
        setErrorState(null)
      } catch (e) {
        switch (e.code) {
          // user rejected the transaction
          case 4001:
            throw e

          // insufficient funds
          case -32000:
            setErrorState(
              "Please deposit ETH into your metamask wallet, insufficient funds to complete transaction."
            )
            throw e

          default:
            throw e
        }
      }
    },
    [contract, wallet.account, auction.minimumBid]
  )

  const onConnectWallet = useCallback(() => {
    wallet.connect("injected")
  }, [wallet])

  const clearErrors = () => setErrorState(null)

  const minimumBid = useMemo(() => {
    return web3.utils.fromWei(auction.minimumBid.toString(), "ether")
  }, [auction.minimumBid])

  return {
    buttonState,
    errorState,
    minimumBid,
    wallet,
    onSettle,
    onBuy,
    clearErrors,
    onConnectWallet,
  }
}
