import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layout/Layout"
import Seo from "../../components/seo"
import AuctionDetails from "../../components/AuctionDetails/AuctionDetails"
import LotDetails from "../../components/LotDetails/LotDetails"
import Carousel from "../../components/Carousel/Carousel"

import Loadable from "@loadable/component"

import playButton from "../../images/play.png"

import * as styles from "./Auction.module.scss"

const LoadableAuctionMonitor = Loadable(
  () => import("../../components/AuctionMonitor/AuctionMonitor")
)

const Auction = ({ data }: PageProps<any>) => {
  const images = useMemo(() => {
    return [
      data.contentfulLot.asset?.mainMedia,
      ...(data.contentfulLot?.additionalMedia ?? []),
    ]
  }, [data])

  return (
    <>
      <Seo title="Auction" />
      <LoadableAuctionMonitor
        auctionId={data.contentfulLot.auctionId}
        render={({ data: auction }: any) => {
          return (
            <div className={styles.auctionGrid}>
              <div>
                <Carousel images={images} />
              </div>
              <AuctionDetails auction={auction} data={data.contentfulLot} />
            </div>
          )
        }}
      />

      <LotDetails data={data.contentfulLot} />
    </>
  )
}

export default Auction

export const lotQuery = graphql`
  query ($lotId: String!) {
    contentfulLot(contentful_id: { eq: $lotId }) {
      auctionId
      lotId
      about {
        about
      }
      estimate
      shortDescription {
        shortDescription
      }
      lotId
      additionalMedia {
        file {
          contentType
          url
        }
        gatsbyImageData(height: 400)
      }
      supportSpecialist {
        name
        email
        title
        profilePicture {
          gatsbyImageData
          title
        }
      }
      asset {
        __typename
        ... on ContentfulNftAsset {
          id
          title
          contractAddress
          medium
          artist {
            name
            nationality
            birthYear
            deathYear
          }
          mainMedia {
            file {
              contentType
              url
            }
            gatsbyImageData(height: 400)
            title
          }
          physicalAsset {
            conditionsReport {
              conditionsReport
            }
            shipsFrom
            shippingDimentions
            frameMaterial
            framedUnder
          }
          tokenId
          title
          yearOfWork
          width
          size
          mintedDate
          height
          length
        }
        ... on ContentfulPhysicalAsset {
          id
          title
          medium
          artist {
            name
            nationality
            birthYear
            deathYear
          }
          conditionsReport {
            conditionsReport
          }
          shipsFrom
          shippingDimentions
          frameMaterial
          framedUnder
          mainMedia {
            gatsbyImageData(height: 400)
            title
          }
          overallWidth
          overallHeight
          overallDepth
          yearOfWork
          title
          imageWidth
          imageHeight
          imageDepth
          framed
          frameWidth
          frameHeight
          frameDepth
          provenance {
            provenance
          }
        }
      }
    }
  }
`
