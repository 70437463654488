import { AuctionStatus, AuctionType } from "@monegraph/graph"
import { Auth } from "aws-amplify"
import { get, includes, split, filter } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState } from "recoil"
import useBuyButton, { BuyButtonState } from "../../hooks/use-buy-button"
import { AuthState, isAuthenticatedState, userState } from "../../state/user"
import { track } from "../../utils/analytics"

import * as styles from "./BuyButton.module.scss"

export const BuyButton = ({
  auction,
  setShowHowBiddingModal,
  setShowBuyersPremium,
}: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const [amount, setAmount] = useState("")
  const [user, setUser] = useRecoilState(userState)

  const {
    buttonState,
    clearErrors,
    errorState,
    wallet,
    onBuy,
    onSettle,
    onConnectWallet,
    minimumBid,
  } = useBuyButton(auction)
  const [authState, setAuthState] = useRecoilState(isAuthenticatedState)

  const userBids = useMemo(
    () =>
      filter(auction.history, (history: any) => {
        return history?.user?.id === wallet.account?.toLowerCase()
      }).length,
    [auction.history, wallet.account]
  )

  useEffect(() => {
    setIsLoading(false)
  }, [userBids])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount((event.target.value || "").replace(/[^0-9.]/g, ""))
  }

  const onBuyClick = useCallback(async () => {
    setIsLoading(true)

    track("bid", { auctionId: auction?.id })

    try {
      clearErrors()
      const user = await Auth.currentAuthenticatedUser()
      const wallets = user.attributes["custom:walletsAddresses"]
        ? split(user.attributes["custom:walletsAddresses"], ",")
        : []

      if (!includes(wallets, wallet.account)) {
        await Auth.updateUserAttributes(user, {
          "custom:walletsAddresses": [...wallets, wallet.account].join(","),
        })
      }

      await onBuy(amount ? amount : auction.minimumBid)
    } catch (error) {
      setIsLoading(false)
    }
  }, [
    amount,
    auction?.id,
    auction.minimumBid,
    clearErrors,
    onBuy,
    wallet.account,
  ])

  const onLogIn = useCallback(() => {
    setAuthState(AuthState.signingIn)
  }, [setAuthState])

  const buttonProps = useMemo(() => {
    if (isLoading) {
      return { label: "processing...", disabled: true }
    }

    if (buttonState === BuyButtonState.Biddable) {
      if (authState === AuthState.unauthenticated) {
        return {
          label: "Place Bid",
          onClick: onLogIn,
        }
      }

      if (wallet.status === "connected") {
        return {
          label: "Place Bid",
          onClick: onBuyClick,
        }
      }
    } else if (buttonState === BuyButtonState.Buyable) {
      if (authState === AuthState.unauthenticated) {
        return {
          label: "Buy Now",
          onClick: onLogIn,
        }
      }
      if (wallet.status === "connected") {
        return {
          label: "Buy Now",
          onClick: onBuyClick,
        }
      }
    } else if (buttonState === BuyButtonState.Settleable) {
      if (wallet.account === auction.seller.id) {
        return { label: "Settle", onClick: onSettle }
      } else {
        return { label: "Sold", onClick: () => {}, disabled: true }
      }
    } else if (buttonState === BuyButtonState.Sold) {
      return { label: "Sold", onClick: () => {}, disabled: true }
    }

    if (!window?.web3) {
      return {
        label: "Web3 Not Available",
        disabled: true,
      }
    }

    if (wallet.status !== "connected") {
      return {
        label: "Connect Wallet",
        onClick: onConnectWallet,
      }
    }
  }, [
    auction.seller.id,
    authState,
    buttonState,
    isLoading,
    onBuyClick,
    onConnectWallet,
    onLogIn,
    onSettle,
    wallet.account,
    wallet.status,
  ])

  const alertProps = useMemo(() => {
    if (authState === AuthState.authenticated) {
      if (errorState) {
        return {
          label: errorState,
          style: {
            color: "rgb(176, 0, 32)",
          },
        }
      }

      const userWallets = split(user?.["custom:walletsAddresses"], ",").map(w =>
        w.toLowerCase()
      )

      if (
        userWallets.includes(auction?.highestBid?.bidder?.id?.toLowerCase())
      ) {
        return {
          label: "You have the current highest bid",
          style: {},
        }
      }
    }
  }, [auction?.highestBid?.bidder?.id, authState, errorState, user])

  const inputError = useMemo(() => {
    if (
      auction?.type === AuctionType.TRADITIONAL &&
      !new RegExp(/^\d*\.?\d*$/).test(amount)
    ) {
      return "Invalid bid"
    }
    return null
  }, [amount, auction?.type])

  const bidInput = useMemo(() => {
    return buttonState === BuyButtonState.Biddable ? (
      <>
        <input
          placeholder={`Enter Bid: ${minimumBid} minimum`}
          value={amount}
          onChange={onChange}
          disabled={
            get(auction, "status", AuctionStatus.OPEN) !== AuctionStatus.OPEN
          }
        />
        {inputError ? <span style={{ color: "red" }}>{inputError}</span> : null}
      </>
    ) : null
  }, [amount, auction, buttonState, inputError, minimumBid])

  return (
    <div>
      <div className={styles.bidDetails}>
        {auction?.type === AuctionType.TRADITIONAL ? (
          <span className={styles.minimumBid}>
            Minimum bid: {minimumBid} ETH
          </span>
        ) : null}

        <button
          onClick={() => setShowHowBiddingModal(true)}
          className={styles.howBiddingWorks}
        >
          How bidding works
        </button>
      </div>
      <div className={styles.auctionDetailsBidInput}>
        {bidInput}

        <div>
          All lots are subject to a 10%{" "}
          <button onClick={() => setShowBuyersPremium(true)}>
            Buyer's Premium
          </button>
          , any applicable taxes, and any shipping costs in addition to the
          final bid amount.
        </div>
      </div>

      <div className={styles.auctionDetailsButton}>
        {buttonProps && (
          <button
            onClick={buttonProps.onClick}
            disabled={buttonProps.disabled || !!inputError}
          >
            {buttonProps.label}
          </button>
        )}
        {alertProps && (
          <div className={styles.alertContainer} style={alertProps.style}>
            {alertProps?.label}
          </div>
        )}
        {/* Comment out over request by Collen */}
        {/* <p>Transfer of NFT subject to KYC/AML Post Auction</p> */}
        <div>
          By placing your bid, you agree to our{" "}
          <a href="/terms-of-use" target="_blank">
            Terms of Use
          </a>
        </div>
      </div>
    </div>
  )
}
