import React, { useMemo, useState } from "react"
import LotDetailPanel from "../LotDetailPanel/LotDetailPanel"

import * as styles from "./LotDetails.module.scss"

const LotDetails = ({ data }: any) => {
  const [showReadMore, setShowReadMore] = useState(false)

  const about = useMemo(() => {
    if (data?.about?.about?.length <= 400 || showReadMore) {
      return data?.about?.about
    } else {
      return `${data?.about?.about?.substring(0, 400)}...`
    }
  }, [data?.about?.about, showReadMore])

  const shipping = useMemo(() => {
    const asset =
      data.asset.__typename === "ContentfulPhysicalAsset"
        ? data.asset
        : data.asset.physicalAsset

    if (asset) {
      return `Ships From: ${asset.shipsFrom}\n
      Shipping Dimensions: ${asset.shippingDimentions}\n
      ${asset.frameMaterial ? `Framed Material: ${asset.frameMaterial}` : ""}\n
      ${asset.framedUnder ? `Framed Under: ${asset.framedUnder}` : ""}\n
      `
    }
  }, [data.asset])

  return (
    <div className={styles.lotDetails}>
      <h2>Lot Details</h2>
      <div className={styles.lotDetailsInner}>
        <div className={styles.lotDetailsDescription}>
          <h3>About This Lot</h3>
          <p>{about}</p>
          {data?.about?.about?.length > 400 && showReadMore && (
            <button onClick={() => setShowReadMore(false)}>Read more</button>
          )}

          {data?.about?.about?.length > 400 && !showReadMore && (
            <button onClick={() => setShowReadMore(true)}>Read Less</button>
          )}
        </div>

        <div className={styles.lotDetailsPanels}>
          <LotDetailPanel
            logoUrl="https://www.artnet.com/auctions/Redesign/Content/svg/auctions-icons/chart.svg"
            title="Condition Report"
            description={
              data?.asset?.conditionsReport?.conditionsReport ||
              data?.asset?.physicalAsset?.conditionsReport?.conditionsReport
            }
          />

          <LotDetailPanel
            logoUrl="https://www.artnet.com/auctions/Redesign/Content/svg/auctions-icons/checkbox.svg"
            title="Shipping Information"
            description={shipping}
          />

          <LotDetailPanel
            logoUrl="https://www.artnet.com/auctions/Redesign/Content/svg/auctions-icons/shipping.svg"
            title="History and Provenance"
            description={data?.asset?.provenance?.provenance}
          />

          <LotDetailPanel
            logoUrl="https://www.artnet.com/auctions/Redesign/Content/svg/auctions-icons/ccard.svg"
            title="Payment and Return Policies"
            description="artnet Assurance Policy: Every artnet Auctions seller has been approved by artnet after a thorough review. All of our sellers are required to accept the following artnet policy: A buyer may return an item purchased through artnet Online Auctions, if the item received is not as described in its listing, or is found to be not authentic."
          />
        </div>
      </div>
    </div>
  )
}

export default LotDetails
