import React from "react"
import { inchesToCentimeters } from "../../utils"

const PhysicalAsset = ({ asset }: any) => {
  return (
    <>
      {asset.medium ? <p>{asset.medium}</p> : null}
      <p>
        Image: {asset.imageHeight} x {asset.imageWidth}{" "}
        {asset.imageDepth ? `x ${asset.imageDepth}` : ""} in. (
        {inchesToCentimeters(asset.imageHeight)} x{" "}
        {inchesToCentimeters(asset.imageWidth)}{" "}
        {asset.imageDepth ? `x ${inchesToCentimeters(asset.imageDepth)}` : ""}{" "}
        cm.)
      </p>
      {asset.overallHeight && asset.overallWidth ? (
        <p>
          Sheet: {asset.overallHeight} x {asset.overallWidth}{" "}
          {asset.overallDepth ? `x ${asset.overallDepth}` : ""} in. (
          {inchesToCentimeters(asset.overallHeight)} x{" "}
          {inchesToCentimeters(asset.overallWidth)}{" "}
          {asset.overallDepth
            ? `x ${inchesToCentimeters(asset.overallDepth)}`
            : ""}{" "}
          cm.)
        </p>
      ) : null}
      {asset.framed && (
        <p>
          Frame: {asset.frameHeight} x {asset.frameHeight}{" "}
          {asset.imageDepth ? `x ${asset.frameDepth}` : ""} in. (
          {inchesToCentimeters(asset.frameHeight)} x{" "}
          {inchesToCentimeters(asset.frameHeight)}{" "}
          {asset.imageDepth ? `x ${inchesToCentimeters(asset.frameDepth)}` : ""}{" "}
          cm.)
        </p>
      )}
      {asset.editionInfo && <p>{asset.editionInfo}</p>}
    </>
  )
}
export default PhysicalAsset
