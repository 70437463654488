import React, { useMemo, useState } from "react"
import { BuyButton } from "../BuyButton/BuyButton"

import Skeleton from "react-loading-skeleton"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { fromUnixTime, formatDistance, isPast } from "date-fns"
import { utcToZonedTime, format } from "date-fns-tz"

import "react-loading-skeleton/dist/skeleton.css"
import * as styles from "./AuctionDetails.module.scss"
import Artist from "./Artist"
import NftAsset from "./NftAsset"
import PhysicalAsset from "./PhysicalAsset"
import Modal from "../Modal/Modal"
import AuctionDetailsModal from "./AuctionDetailsModal"
import { graphql, useStaticQuery } from "gatsby"
import { AuctionStatus } from "@monegraph/graph"
import { get } from "lodash"

const AuctionDetails = ({ auction, data }: any) => {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulHowBiddingWorks {
        edges {
          node {
            body {
              raw
            }
          }
        }
      }

      allContentfulBuyersPremium {
        edges {
          node {
            body {
              raw
            }
          }
        }
      }
    }
  `)

  const [showHowBiddingModal, setShowHowBiddingModal] = useState(false)
  const [showBuyersPremium, setShowBuyersPremium] = useState(false)

  const { endingLabel, ending, dateFormated }: any = useMemo(() => {
    if (auction) {
      const date = new Date()
      const dateTimezoned = utcToZonedTime(date, "EST")

      const endingDate = fromUnixTime(auction.dateEnding)
      const endingDateTimezoned = utcToZonedTime(endingDate, "EST")

      const ending = formatDistance(endingDateTimezoned, dateTimezoned, {
        addSuffix: true,
      })

      const dateFormated = format(
        endingDateTimezoned,
        "MMMM dd, YYY 'at' hh:mm aaaa (zzz)"
      )

      return {
        endingLabel: isPast(endingDateTimezoned) ? "Ended:" : "Ending:",
        ending,
        dateFormated,
      }
    }

    return {
      endingLabel: "Ending:",
      ending: "",
      dateFormated: "",
    }
  }, [auction])

  return (
    <div className={styles.auctionDetails}>
      <Artist artist={data?.asset?.artist} />

      <div className={styles.auctionDetailsTitle}>
        <p>
          {data?.asset.title}, {`${data?.asset.yearOfWork ?? ""}`}
        </p>
      </div>
      <div className={styles.auctionDetailsInfo}>
        {data.asset.__typename === "ContentfulNftAsset" && (
          <NftAsset asset={data.asset} />
        )}
        {data.asset.__typename === "ContentfulPhysicalAsset" && (
          <PhysicalAsset asset={data.asset} />
        )}

        {data?.asset.contractAddress && (
          <>
            <p>
              Contract Address:{" "}
              {auction ? auction.id : <Skeleton width="100px" />}
            </p>
          </>
        )}
        <p>{data?.shortDescription?.shortDescription}</p>
        <p style={{ opacity: 0.5, marginTop: "1rem" }}>
          Lot ID: <span>{data.lotId}</span>
        </p>
        <div>
          {data.asset.__typename === "ContentfulNftAsset" ? (
            <span className={styles.assetPill}>NON-FUNGIBLE TOKEN</span>
          ) : null}
          {data.asset.__typename === "ContentfulPhysicalAsset" ||
          data.asset?.physicalAsset ? (
            <span className={styles.assetPill}>PHYSICAL ARTWORK</span>
          ) : null}
        </div>
      </div>
      <div className={styles.auctionDetailsEnding}>
        <div className={styles.auctionDetailsLabel}>{endingLabel}</div>
        {auction ? (
          <div className={styles.auctionDetailsEndingInner}>
            <p>{ending}</p>
            <p className={styles.auctionDetailsEndDate}>{dateFormated}</p>
          </div>
        ) : (
          <Skeleton width="100px" />
        )}
      </div>

      {get(auction, "status", AuctionStatus.OPEN) === AuctionStatus.OPEN ? (
        <div className={styles.auctionDetailsEstimate}>
          <div className={styles.auctionDetailsLabel}>Estimate: </div>
          <p>{data.estimate} ETH</p>
        </div>
      ) : null}

      <div className={styles.auctionDetailsCurrentBid}>
        <div className={styles.auctionDetailsLabel}>Current Bid:</div>
        <div className={styles.auctionDetailsCurrentBidInner}>
          <div>{auction ? `${auction.currentBidInETH} ETH` : <Skeleton />}</div>
          {auction ? (
            <p>
              {auction.history?.length === 1
                ? "1 Bid"
                : `${auction.history?.length} Bids`}
            </p>
          ) : (
            <Skeleton width="50px" />
          )}
        </div>
      </div>
      <div className={styles.auctionDetailsBuyBtn}>
        {auction ? (
          <BuyButton
            auction={auction}
            setShowHowBiddingModal={setShowHowBiddingModal}
            setShowBuyersPremium={setShowBuyersPremium}
          />
        ) : (
          <Skeleton width="100%" height="35px" />
        )}
      </div>
      <div className={styles.auctionDetailsProfile}>
        <div className={styles.auctionDetailsProfileInner}>
          <div className={styles.auctionDetailsProfileImgBox}>
            <GatsbyImage
              image={getImage(data?.supportSpecialist?.profilePicture)!!}
              alt={data?.supportSpecialist?.profilePicture.title}
              style={{ minHeight: "100%", minWidth: "100%" }}
            />
          </div>
          <div>
            <ul className={styles.auctionDetailsProfileList}>
              <li>{data?.supportSpecialist?.name}</li>
              <li>{data?.supportSpecialist?.title}</li>
            </ul>
            <div className={styles.auctionDetailsProfileEmail}>
              <a
                href={`mailto:${data?.supportSpecialist?.email}?subject=Lot ID: ${data.lotId}`}
                target="_blank"
                rel="noreferrer"
              >
                MESSAGE {data?.supportSpecialist?.name?.toUpperCase()}
              </a>
            </div>
          </div>
        </div>
      </div>

      {showHowBiddingModal && (
        <Modal closeModal={() => setShowHowBiddingModal(false)}>
          <AuctionDetailsModal
            closeModal={() => setShowHowBiddingModal(false)}
            data={queryData.allContentfulHowBiddingWorks}
          />
        </Modal>
      )}

      {showBuyersPremium && (
        <Modal closeModal={() => setShowBuyersPremium(false)}>
          <AuctionDetailsModal
            closeModal={() => setShowBuyersPremium(false)}
            data={queryData.allContentfulBuyersPremium}
          />
        </Modal>
      )}
    </div>
  )
}

export default AuctionDetails
