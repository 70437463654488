// extracted by mini-css-extract-plugin
export var auctionDetails = "AuctionDetails-module--auctionDetails--wBqmr";
export var auctionDetailsAuthorBox = "AuctionDetails-module--auctionDetailsAuthorBox---p38S";
export var auctionDetailsTitle = "AuctionDetails-module--auctionDetailsTitle--8lMaf";
export var auctionDetailsInfo = "AuctionDetails-module--auctionDetailsInfo--Scxkm";
export var assetPill = "AuctionDetails-module--assetPill--J4St7";
export var auctionDetailsEnding = "AuctionDetails-module--auctionDetailsEnding--0-tbF";
export var auctionDetailsLabel = "AuctionDetails-module--auctionDetailsLabel--5PFjZ";
export var auctionDetailsEndingInner = "AuctionDetails-module--auctionDetailsEndingInner--RLOY3";
export var auctionDetailsEndDate = "AuctionDetails-module--auctionDetailsEndDate--6c6aL";
export var auctionDetailsEstimate = "AuctionDetails-module--auctionDetailsEstimate--rd9tn";
export var auctionDetailsCurrentBid = "AuctionDetails-module--auctionDetailsCurrentBid--9HOGH";
export var auctionDetailsCurrentBidInner = "AuctionDetails-module--auctionDetailsCurrentBidInner--l5FS6";
export var auctionDetailsBuyBtn = "AuctionDetails-module--auctionDetailsBuyBtn--SdVov";
export var auctionDetailsProfile = "AuctionDetails-module--auctionDetailsProfile--qU5sb";
export var auctionDetailsProfileInner = "AuctionDetails-module--auctionDetailsProfileInner--G3OoX";
export var auctionDetailsProfileImgBox = "AuctionDetails-module--auctionDetailsProfileImgBox--+lIlU";
export var auctionDetailsProfileList = "AuctionDetails-module--auctionDetailsProfileList--jdUXR";
export var auctionDetailsProfileEmail = "AuctionDetails-module--auctionDetailsProfileEmail--T4OR9";
export var auctionDetailsModal = "AuctionDetails-module--auctionDetailsModal--0j11V";
export var auctionDetailsModalClose = "AuctionDetails-module--auctionDetailsModalClose--vNKx2";
export var auctionDetailsRichText = "AuctionDetails-module--auctionDetailsRichText--2saVO";