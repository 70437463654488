import React from "react"
import * as styles from "./AuctionDetails.module.scss"

const Artist = ({ artist }: any) => {
  return (
    <div className={styles.auctionDetailsAuthorBox}>
      <h3>{artist.name}</h3>
      <p>{`${artist.nationality}, ${artist.birthYear ?? ""} ${
        artist.deathYear ? "- " + artist.deathYear : ""
      }`}</p>
    </div>
  )
}

export default Artist
