import React, { useMemo } from "react"

import { utcToZonedTime, format } from "date-fns-tz"

const NftAsset = ({ asset }: any) => {
  const mintedDate = useMemo(() => {
    if (asset.mintedDate) {
      const zonedDate = utcToZonedTime(new Date(), "America/New_York")
      return format(zonedDate, "MMMM dd, YYY 'at' hh:mm aaaa (zzz)")
    }
  }, [asset])

  return (
    <>
      {asset.medium ? <p>{asset.medium}</p> : null}
      <p>Size: {asset.size} Mb</p>
      {asset.tokenId ? <p>Token Id: {asset.tokenId}</p> : null}
      {mintedDate && <p>Minted: {mintedDate}</p>}
      {asset.height && asset.width ? (
        <p>Dimensions: {`${asset.height} x ${asset.width} px`}</p>
      ) : null}
    </>
  )
}
export default NftAsset
