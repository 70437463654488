import React, { useState, useEffect, useCallback } from "react"
import { DotButton, PrevButton, NextButton } from "./EmblaCarouselButtons"
import useEmblaCarousel from "embla-carousel-react"
import { useMediaQuery } from "react-responsive"
import cn from "classnames"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import playButton from "../../images/play.png"

import * as styles from "./Carousel.module.scss"

interface EmblaCarouselProps {
  images: Array<any>
}

const EmblaCarousel = ({ images }: EmblaCarouselProps) => {
  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelect)
  }, [embla, setScrollSnaps, onSelect])

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  })

  return (
    <div className={styles.carousel}>
      <div className={styles.embla}>
        <div className={styles.embla__viewport} ref={viewportRef}>
          <div className={styles.embla__container}>
            {images.map((image, i) => (
              <div className={styles.embla__slide} key={i}>
                <div className={styles.embla__slide__inner}>
                  {image?.file?.contentType?.includes("video") ? (
                    <video
                      width="100%"
                      autoPlay
                      muted
                      loop
                      controls
                      playsInline
                      controlsList="nofullscreen nodownload noremoteplayback"
                    >
                      <source src={image.file.url} type="video/mp4" />
                    </video>
                  ) : (
                    <GatsbyImage image={getImage(image)} alt={image.title} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} /> */}
      </div>
      <div className={styles.embla__dots}>
        {isDesktop ? (
          <div className={styles.carouselImgBtns}>
            {images.map((image, i) => (
              <button
                key={i}
                className={cn({
                  [styles.isSelected]: i === selectedIndex,
                })}
                type="button"
                onClick={() => scrollTo(i)}
              >
                {image?.file?.contentType?.includes("video") ? (
                  <div className={styles.embla__video}>
                    <video width="100%" autoPlay>
                      <source src={image.file.url} type="video/mp4" />
                    </video>
                    <div className={styles.embla__video__overlay}>
                      <img
                        src={playButton}
                        alt="play button"
                        width="30px"
                        height="30px"
                      />
                    </div>
                  </div>
                ) : (
                  <GatsbyImage image={getImage(image)} alt={image.title} />
                )}
              </button>
            ))}
          </div>
        ) : (
          scrollSnaps.map((_, index) => (
            <button
              key={index}
              className={cn(styles.embla__dot, {
                [styles.isSelected]: index === selectedIndex,
              })}
              type="button"
              onClick={() => scrollTo(index)}
            >
              {" "}
            </button>
          ))
        )}
        {}
      </div>
    </div>
  )
}

export default EmblaCarousel
