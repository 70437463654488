import React, { useEffect, useRef, useState } from "react"
import { useSpring, animated } from "@react-spring/web"
import * as styles from "./LotDetailPanel.module.scss"

interface LotDetailPanelProps {
  logoUrl: string
  title: string
  description?: string
}

const LotDetailPanel = ({
  logoUrl,
  title,
  description,
}: LotDetailPanelProps) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLParagraphElement>(null)
  const [animatedStyles, animate] = useSpring(() => ({ height: "0px" }), [])

  useEffect(() => {
    animate({
      height: (open ? ref?.current?.offsetHeight : 0) + "px",
    })
  }, [animate, ref, open])

  if (!description) return <></>

  return (
    <div className={styles.lotDetailPanel}>
      <button onClick={() => setOpen(!open)}>
        <div className={styles.lotDetailPanelImgBox}>
          <img src={logoUrl} alt={title} />
        </div>
        <p>{title}</p>
      </button>
      <animated.div
        style={animatedStyles}
        className={styles.lotDetailPanelDescription}
      >
        <p ref={ref}>{description}</p>
      </animated.div>
    </div>
  )
}

export default LotDetailPanel
